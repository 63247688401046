import React, { Component } from "react";

export default class Discovery extends Component {
  
  componentDidMount() {
    const script = document.createElement("script");

    script.src = "https://formfacade.com/include/106419382112467720160/form/1FAIpQLScCW9nKq4XjJn9r_7cdQlbPv7zWYaPaxWKSHJjCOm0MUH1EzQ/classic.js?div=ff-compose";
    script.async = true;

    document.body.appendChild(script);
  }
  
  render() {
    return (
      <div style={{paddingTop: 100}}>
        <div id="ff-compose" />
        <div className="container" />
      </div>
    );
  }
}