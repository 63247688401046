import React from "react"
import Discovery from "../components/discovery"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"

const DiscoveryPage = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Discovery"/>
        <Discovery />
      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default DiscoveryPage
